import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import { navigate } from "gatsby-link";
import api from "../api";
import SEO from "../components/seo";
import { Button } from "antd";
import { AuthContext } from "../context/GlobalContextProvider";
import PredioComponent from "./generics/predios";

export default function Pronto() {
  const [data, setData] = useState();
  const context = useContext(AuthContext);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    var response = await api.get("/predios?estado=Pronto");
    setData(response.data);
  };
  return (
    <Layout aboveFooter titlePage="Pronto para morar" loading={!data}>
      <SEO title="Pronto para morar" />
      {context?.user && (
        <Button
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/pronto`)}
        >
          Editar
        </Button>
      )}
      <PredioComponent buildings={data} />
    </Layout>
  );
}
